import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';
import { colors } from 'material/colors';

export default makeStyles<ICogniflowTheme>(({ breakpoints }) => ({
  planCard: {
    position: 'relative',
    overflow: 'visible',
    flexShrink: 0,
    padding: '20px',
    maxWidth: '175px',
    height: '550pxpx',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
      transform: 'translateY(-2px)',
    },

    '& p': {
      fontSize: '11px',
    },

    [breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '270px',
    },
    [breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  planCardHeader: {
    height: '45px',
  },

  cardTitle: {
    marginBottom: '12px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },

  noActionspace: {
    height: '61px',
  },

  currentPlanCard: {
    background: colors.lightOrange,
    border: `1px solid ${colors.orange}`,
    boxShadow:
      '0px 2px 1px -1px rgba(255, 106, 97, 0.2), 0px 1px 1px 0px rgba(255, 106, 97, 0.14), 0px 1px 3px 0px rgba(255, 106, 97, 0.12)',
  },
  currentPlanCheck: {
    // backgroundColor: colors.orange,
    border: `2px solid ${colors.orange}`,
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.orange,
    fontSize: '10px',
  },

  chip: {
    fontSize: '10px',
    padding: '0 4px',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: colors.orange,
  },

  planPrice: {
    margin: '60px 0 18px 0',
  },

  priceNumber: {
    position: 'relative',
  },
  priceSym: {
    top: '6px',
    position: 'absolute',
    fontSize: '14px',
    left: '-10px',
    fontWeight: 'normal',
  },

  upgradeButton: {
    height: '48px',
  },

  planFeatures: {
    marginTop: '24px',
  },

  featureItemIcon: {
    color: colors.blue70,
  },

  specialFeature: {
    '& p': {
      color: colors.orange,
    },
  },

  specialButton: {
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
}));
