import { PlanData, PlAN_IDS } from 'types';

import { getEnv } from 'services';

export enum PERIODS {
  MONTH = 1,
  ANNUAL = 0,
}

const free = {
  id: 'eeffaabb-ae29-4992-bb8f-139448dd9d02',
  planName: 'Free',
  description: 'For individuals starting with AI',
  price: 0,
  credits: '100',
  period: 'free',
  trainingHours: 5,
  users: '1 user',
  actionButtonText: 'Upgrade',
  hasActionButton: false,
  properties: [
    'Non-commercial use',
    'Build custom AI models',
    'Chat with your documents',
    'Access to our pre-trained AI models',
    'Smart Extraction',
    'Chat with your spreadsheet (coming soon)',
  ],
};

const starter = {
  id: PlAN_IDS.STARTER,
  planName: 'Starter',
  description: 'For individuals starting to integrate AI into their workflows',
  price: 19,
  credits: '5,000',
  trainingHours: 10,
  users: '1 user',
  period: 'per month | billed monthly',
  hasActionButton: true,
  actionButtonText: 'Upgrade',
  properties: [
    'Commercial use',
    'Chat and email support',
    'Long audio transcription via Webhooks',
    'Publish models as Web Apps',
    'Premium AI models',
  ],
};

const team = {
  id: PlAN_IDS.TEAM,
  planName: 'Team',
  description:
    'For individuals or small teams that need more power and collaboration features',
  price: 49,
  credits: '15,000',
  trainingHours: 20,
  users: 'Up to 3 users',
  period: 'per month | billed monthly',
  actionButtonText: 'Upgrade',
  hasActionButton: true,
  properties: [
    'Create an Organization',
    'Faster response speed',
    'Option to use GPT-4',
  ],
};

const business = {
  id: PlAN_IDS.BUSINESS,
  planName: 'Business',
  description: 'For organizations looking to scale AI.',
  price: 199,
  credits: '100,000',
  trainingHours: 50,
  users: 'Unlimited users',
  period: 'per month | billed monthly',
  actionButtonText: 'Upgrade',
  hasActionButton: true,
  properties: [
    'Priority support',
    'Private onboarding session',
    'Remove Cogniflow branding',
    'Priority access to new features',
  ],
};

export const plansInfo: { [key: number]: PlanData[] } = {
  [PERIODS.MONTH]: [free, starter, team, business],
  [PERIODS.ANNUAL]: [
    free,
    { ...starter, price: 15, period: 'per month | billed annually' },
    { ...team, price: 35, period: 'per month | billed annually' },
    { ...business, price: 140, period: 'per month | billed annually' },
  ],
};

export const promotionalPlansInfo: PlanData[] = [
  {
    id: '5b98aae4-2b11-4577-8a5c-d0c3a78eaa79',
    planName: 'Starter Plan',
    description: 'Start your first real AI products',
    price: 150,
    discountPercentage: 0.9,
    period: 'for 3 months',
    hasActionButton: true,
    actionButtonText: 'Upgrade',
    properties: [
      '5,000 predictions/month',
      '15 custom experiments/month (*)',
      '1 user',
      'email support',
      'commercial use',
      '<strong>Object Detection (only available on Pro Plan)</strong>',
    ],
    additionalPredictionText: ['(*) up to 25 training hours/month'],
  },
];

export const paymentLinks: { [key: number]: any } = {
  [PERIODS.MONTH]: {
    [PlAN_IDS.STARTER]: getEnv('REACT_APP_PAYMENT_LINK_STARTER_MONTHLY_STRIPE'),
    [PlAN_IDS.TEAM]: getEnv('REACT_APP_PAYMENT_LINK_TEAM_MONTHLY_STRIPE'),
    [PlAN_IDS.BUSINESS]: getEnv(
      'REACT_APP_PAYMENT_LINK_BUSINESS_MONTHLY_STRIPE'
    ),
  },
  [PERIODS.ANNUAL]: {
    [PlAN_IDS.STARTER]: getEnv(
      'REACT_APP_PAYMENT_LINK_STARTER_ANNUALLY_STRIPE'
    ),
    [PlAN_IDS.TEAM]: getEnv('REACT_APP_PAYMENT_LINK_TEAM_ANNUALLY_STRIPE'),
    [PlAN_IDS.BUSINESS]: getEnv(
      'REACT_APP_PAYMENT_LINK_BUSINESS_ANNUALLY_STRIPE'
    ),
  },
};

export const redirectToStripePage = (email: string) => {
  window.location.href = `${getEnv(
    'REACT_APP_CUSTOMER_PORTAL_LINK_STRIPE'
  )}?prefilled_email=${email}`;
};
