import React, { FC, ReactNode, useMemo } from 'react';
import { Box, Button, Card } from '@material-ui/core';
import useStyles from './PlanCard.styles';
import classnames from 'classnames';

import { Text } from 'components';
import { PlanData, planIndex, PlAN_IDS, User, PROMO_CODE } from 'types';

import { getEnv } from 'services';
import { PERIODS } from 'pages/Settings/Billing/plans';

const DISCOUNT_COLOR = '#ff3d00';

export interface PlanCardProps extends PlanData {
  index: number;
  user: User | undefined | null;
  isCurrentPlan?: boolean;
  onUpgradeHandler(planId: string): void;
  disabled?: boolean;
  discountPercentage?: number;
  timeLapse?: number;
  hasStripeSubscription?: boolean;
}

const allIncludedDataMap: { [key: string]: string } = {
  [PlAN_IDS.STARTER]: 'Free',
  [PlAN_IDS.TEAM]: 'Starter',
  [PlAN_IDS.BUSINESS]: 'Team',
};

export const PlanCard: FC<PlanCardProps> = ({
  id,
  isCurrentPlan,
  planName,
  description,
  price,
  period,
  properties,
  user,
  users,
  trainingHours,
  credits,
  onUpgradeHandler,
  disabled = false,
  discountPercentage,
  timeLapse,
  hasStripeSubscription = false,
}) => {
  const classes = useStyles();

  const canDoUpgrade = useMemo(() => {
    if (!user) return false;

    return planIndex[user.commercial_plan.id] < planIndex[id];
  }, [user, id]);

  const isFree = useMemo(() => id === PlAN_IDS.FREE, [id]);

  const showDiscount =
    canDoUpgrade && price > 0 && discountPercentage && discountPercentage > 0;

  const redirectToStripe = () => {
    window.location.href = `${getEnv(
      'REACT_APP_CUSTOMER_PORTAL_LINK_STRIPE'
    )}?prefilled_email=${user?.email}`;
  };

  const FeatureItem = ({
    icon,
    children,
    iconSize,
  }: {
    icon?: ReactNode;
    children: ReactNode;
    iconSize?: string;
  }) => (
    <Box display="flex" gridGap="8px" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        fontSize={isFree ? '12px' : iconSize}
      >
        {isFree ? <>&#10004;</> : icon || <>&#10004;</>}
      </Box>
      <Text variant="paragraph1" align="left">
        {children}
      </Text>
    </Box>
  );

  return (
    <Card
      className={classnames({
        [classes.planCard]: true,
        [classes.currentPlanCard]: isCurrentPlan,
      })}
      style={{
        border: showDiscount
          ? `2px solid ${DISCOUNT_COLOR}`
          : isCurrentPlan
          ? '2px solid #ff3d001a'
          : '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      {showDiscount && (
        <>
          <Box
            style={{
              position: 'absolute',
              top: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              background: DISCOUNT_COLOR,
              color: 'white',
              padding: '2px 12px',
              borderRadius: '12px',
              fontSize: '11px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              boxShadow: `0 2px 4px ${DISCOUNT_COLOR}33`,
            }}
          >
            Save {Math.round(discountPercentage * 100)}%
          </Box>

          {hasStripeSubscription && (
            <Box
              style={{
                background: 'white',
                border: `1px solid ${DISCOUNT_COLOR}`,
                color: DISCOUNT_COLOR,
                padding: '2px 12px',
                margin: '-3px 0 12px',
                borderRadius: '12px',
                fontSize: '11px',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                boxShadow: `0 2px 4px ${DISCOUNT_COLOR}33`,
              }}
            >
              Use promo code: {PROMO_CODE}
            </Box>
          )}
        </>
      )}
      <Box className={classes.planCardHeader}>
        <Text variant="h3" align="left" className={classes.cardTitle}>
          {planName}
        </Text>
        <Text variant="paragraph1" align="left">
          {description}
        </Text>
      </Box>

      <Box className={classes.planPrice}>
        <Text variant="h2" style={{ position: 'relative' }} align="left">
          <span className={classes.priceSym}>$</span>
          {showDiscount ? (
            <>
              <span
                style={{
                  textDecoration: 'line-through',
                  color: '#999',
                  fontSize: '0.6em',
                  marginRight: '12px',
                  position: 'relative',
                  top: '-5px',
                }}
              >
                {price}
              </span>
              <span
                style={{
                  color: DISCOUNT_COLOR,
                  fontWeight: 'bold',
                  fontSize: '1.25em',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {Math.round(price * (1 - discountPercentage) * 100) / 100}
                {timeLapse === PERIODS.MONTH && (
                  <span style={{ fontSize: '0.35em', marginLeft: '6px' }}>
                    x 6 months
                  </span>
                )}
              </span>
            </>
          ) : (
            price
          )}
        </Text>
        <Text variant="paragraph1" align="left">
          {period}
        </Text>
      </Box>

      {!disabled && (
        <>
          {canDoUpgrade ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classnames({
                [classes.upgradeButton]: true,
              })}
              onClick={() => {
                onUpgradeHandler(id);
              }}
            >
              {'Upgrade'}
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classnames({
                [classes.upgradeButton]: true,
              })}
              disabled={isCurrentPlan}
              onClick={redirectToStripe}
            >
              {isCurrentPlan ? 'Current plan' : 'Downgrade'}
            </Button>
          )}
        </>
      )}

      <Box className={classes.planFeatures}>
        <Box display="flex" flexDirection="column" gridGap="8px">
          <FeatureItem iconSize="12px">{credits} credits/mo</FeatureItem>
          <FeatureItem iconSize="12px">
            {trainingHours} training hours/mo
          </FeatureItem>

          <FeatureItem iconSize="12px">{users}</FeatureItem>

          {!isFree && (
            <FeatureItem iconSize="12px">
              All included in plan <b>{allIncludedDataMap[id]}</b>
            </FeatureItem>
          )}
          {properties.map((text: string) => (
            <FeatureItem icon={<>&#43;</>}>{text}</FeatureItem>
          ))}
        </Box>
      </Box>
    </Card>
  );
};
