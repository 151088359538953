import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    '& *': {
      color: 'white',
    },
  },
  closeButton: {
    color: 'white',
  },
}));
