import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyle from './MainBanner.styles';
import { useLocation } from 'react-router-dom';
import { useAuthenticationContext } from 'context';

interface MainBannerProps {
  message: string;
  visiblePaths?: string[];
  commercialPlanIds?: string[];
  backgroundColor?: string;
}

export const MainBanner = ({
  message,
  visiblePaths = [],
  commercialPlanIds = [],
  backgroundColor = '#4CAF50',
}: MainBannerProps) => {
  const classes = useStyle();
  const { pathname: location } = useLocation();
  const [isClosed, setIsClosed] = useState(false);
  const { user } = useAuthenticationContext();

  const hasCommercialPlan =
    commercialPlanIds.length === 0 ||
    commercialPlanIds.includes(user?.commercial_plan?.id || '');

  const isVisible =
    (visiblePaths.length === 0 ||
      visiblePaths.some(
        (path) => location === path || location.startsWith(path)
      )) &&
    hasCommercialPlan;

  if (!isVisible || isClosed) return null;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      style={{ backgroundColor }}
    >
      <div></div>
      <div
        dangerouslySetInnerHTML={{ __html: message }}
        style={{ fontSize: '1rem', textAlign: 'center', flex: 1 }}
      />
      <IconButton
        className={classes.closeButton}
        onClick={() => setIsClosed(true)}
        aria-label="Close banner"
        size="small"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
