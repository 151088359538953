import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Text, Hints } from 'components';
import TextTutorial from 'assets/text-tutorial.gif';
import ImageTutorial from 'assets/image-tutorial.gif';
import AudioTutorial from 'assets/audio-tutorial.gif';
import ExtractorTutorial from 'assets/extractor-tutorial.gif';
import ObjectTutorial from 'assets/object-tutorial.gif';
import KbTutorial from 'assets/kb-tutorial.gif';
import KbDataTableTutorial from 'assets/kb-data-table-tutorial.gif';

import { PROJECT_TASK, ProjectTaskLabels } from 'types';
import useStyles from './ProjectHints.styles';

export interface ProjectHintsProps {
  task: number;
  type: number;
  mode?: string;
}

export const ProjectHints: FC<ProjectHintsProps> = ({ task, type, mode }) => {
  const classes = useStyles();

  const hintsMap: Record<number, string> = {
    [PROJECT_TASK.CLASSIFIER_TEXT]: TextTutorial,
    [PROJECT_TASK.CLASSIFIER_IMAGE]: ImageTutorial,
    [PROJECT_TASK.CLASSIFIER_AUDIO]: AudioTutorial,
    [PROJECT_TASK.CHATBOT]:
      mode === 'spreadsheet' ? KbDataTableTutorial : KbTutorial,
    [PROJECT_TASK.EXTRACTOR]: ExtractorTutorial,
    [PROJECT_TASK.OBJECT_DETECTION]: ObjectTutorial,
  };

  return (
    <Box padding="14px">
      <Box mb="24px">
        <Text align="center" variant="h3">
          File requirements and dataset structure
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" gridGap="8px">
        <Box>
          <Hints task={task} type={type} mode={mode} />
        </Box>
        <Box display="flex">
          <img src={hintsMap[task]} alt="animation help" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};
