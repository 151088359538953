import React, { ReactNode } from 'react';
import { Link, Typography, Box } from '@material-ui/core';
import { EXPERIMENT_TASK, PROJECT_TYPE } from 'types';
import { TextTips } from './TextTips';
import { Text } from 'components';

type TipsItem = { [key: number]: ReactNode[] };

type TipsProps = {
  type: number;
  task: number;
  mode?: string;
};

export const Tips = ({ type, task, mode }: TipsProps) => {
  const tipsForText: TipsItem = {
    [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: [
      <Text variant="paragrahp1">
        The file must be a <b>CSV</b>.
      </Text>,
      <Text variant="paragrahp1">
        You need to select two columns, one for the <b>Text</b> (input) and the
        other for the <b>Label</b> (category result).
      </Text>,
      <Text variant="paragrahp1">
        It is required a minimun of 5 different samples per label.
      </Text>,
      <Text variant="paragraph1">
        Read more in our{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-sentiment-analysis-text-data-1kjtxhh/"
          target="_blank"
        >
          tutorial.
        </Link>{' '}
      </Text>,
    ],
    [EXPERIMENT_TASK.TEXT_QANDA]:
      mode === 'spreadsheet'
        ? [
            <Text variant="paragrahp1">
              Think of your dataset as a structured repository of information
              for building a data-driven chatbot.
            </Text>,
            <Text variant="paragrahp1">
              <b>Supported file formats:</b> Uploads should be in .csv, .xls or
              .xlsx formats.
            </Text>,
            <Text variant="paragrahp1">
              <b>Headers:</b> Ensure the first row contains column headers that
              clearly define the data fields (e.g., "Name," "Age," "Revenue").
            </Text>,
            <Text variant="paragrahp1">
              <b>Data Structure:</b> The file should have a tabular structure
              with rows representing individual records and columns representing
              attributes.
            </Text>,
            <Text variant="paragrahp1">
              <b>Important:</b> Avoid merged cells or inconsistent row lengths.
              Clean and complete data improves performance.
            </Text>,
          ]
        : [
            <Text variant="paragrahp1">
              Think your knowledge base as a repository of information about one
              or more topics of your interest,
            </Text>,
            <Text variant="paragrahp1">
              Supported file formats to upload include most common document
              types: txt, doc, docx and pdf.,
            </Text>,
            <Text variant="paragrahp1">
              Create a single <strong>zip</strong> file with all the documents
              that will be part of the knowledge base.
            </Text>,
            <Text variant="paragrahp1">
              <b>Important:</b> Tables are not fully supported yet. For best
              performance, we recommend using paragraph-based documents whenever
              is possible
            </Text>,
          ],
    [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: [
      <Box>
        <Text variant="paragraph1">
          The more specific about the info you want to extract the better
        </Text>
      </Box>,
      <Box>
        <Text variant="paragraph1">We recommend to provide examples</Text>
      </Box>,
      <Box>
        <Text variant="paragraph1">You can change this definition later </Text>
      </Box>,
    ],
  };
  const tipsForImage: TipsItem = {
    [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: [
      <Text variant="paragrahp1">
        The file must be a <b>ZIP</b> that contains multiple folders.
      </Text>,
      <Text variant="paragrahp1">
        Each folder represents the category to be classified, which contains the
        images.
      </Text>,
      <Text variant="paragrahp1">
        The images must be in <b>jpg</b> or <b>png</b> format.
      </Text>,
      <Text variant="paragrahp1">
        It requires a minimum of 5 different images per category. We recommend
        starting with 50.
      </Text>,
      <Text variant="paragraph1">
        Read more in our{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-image-recognition-t6n68m/"
          target="_blank"
        >
          tutorial.
        </Link>{' '}
      </Text>,
    ],
    [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: [
      <Text variant="paragraph1">
        The file must be a <b>ZIP</b> that follows the{' '}
        <Link
          underline="always"
          color="secondary"
          href="https://docs.cogniflow.ai/en/article/how-to-create-a-dataset-for-object-detection-using-the-yolo-labeling-format-1tahk19/"
          target="_blank"
        >
          YOLO
        </Link>{' '}
        format structure.
      </Text>,
      <Text variant="paragraph1">
        We recommend our{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://lblstudio.cogniflow.ai/user/login/"
          target="_blank"
        >
          labeling tool
        </Link>{' '}
        to create and export the dataset in YOLO format. Use your Cogniflow
        credentials to sign in.
      </Text>,
      <Text variant="paragraph1">
        We support <b>jpg</b> or <b>png</b> image formats.
      </Text>,
      <Text variant="paragraph1">
        Follow our{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://docs.cogniflow.ai/en/article/how-to-label-images-for-an-object-detection-model-dcfg1y/"
          target="_blank"
        >
          step-by-step tutorial.
        </Link>{' '}
      </Text>,
    ],
    [EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR]: [
      <Box>
        <Text variant="paragraph1">
          The more specific about the info you want to extract the better
        </Text>
      </Box>,
      <Box>
        <Text variant="paragraph1">We recommend to provide examples</Text>
      </Box>,
      <Box>
        <Text variant="paragraph1">You can change this definition later </Text>
      </Box>,
    ],
  };
  const tipsForAudio: TipsItem = {
    [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: [
      <Text variant="paragraph1">
        The file must be a <b>ZIP</b> that contains multiple folders. Each
        folder represents the category to be classified, which contains the
        audios.
      </Text>,
      <Text variant="paragrahp1">
        It requires a minimum of 5 audios per category. The more examples, the
        more accurate the model will later be.
      </Text>,
      <Text variant="paragraph1">
        You can use <b>mp3</b>, <b>wav</b>, <b>ogg</b>, or any other format
        supported by{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://ffmpeg.org/ffmpeg-formats.html"
          target="_blank"
        >
          FFmpeg
        </Link>
      </Text>,
      <Text variant="paragraph1">
        We recommend short audio files between 1 and 15 secs long.
      </Text>,
      <Text variant="paragraph1">
        Read more in our{' '}
        <Link
          color="secondary"
          underline="always"
          href="https://docs.cogniflow.ai/en/article/how-to-create-an-ai-for-sound-classification-1dakrxb/"
          target="_blank"
        >
          tutorial.
        </Link>{' '}
      </Text>,
    ],
  };

  return (
    <>
      {type === PROJECT_TYPE.IMAGE && (
        <TextTips task={task} tips={tipsForImage} />
      )}

      {type === PROJECT_TYPE.AUDIO && (
        <TextTips task={task} tips={tipsForAudio} />
      )}

      {type === PROJECT_TYPE.TEXT && (
        <TextTips task={task} tips={tipsForText} />
      )}
    </>
  );
};
